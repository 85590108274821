import React from 'react';
import '../style.css';

function Footer (){
    return (
        <div className="footer">© Copyright 2023 - Designed and Built by Anca Agapi</div>

    )
}

export default Footer;